
import Vue from "vue";
import ReportRenderer from "@/components/reports/ReportRenderer.vue";
import CompetencyMatrix, { MatrixView } from "@/components/admin/CompetencyMatrix.vue";
import CandidateSearchTable from "@/components/admin/CandidateSearchTable.vue";
import CompetenciesListing from "@/components/admin/CompetenciesListing.vue";
import CompetenciesToggleSlideout from "@/components/admin/CompetenciesToggleSlideout.vue";
import ComparisonRadarChartComponent, { ComparisonAssessmentItem, ComparisonBenchmarkItem, ComparisonChartPreset } from "@/components/admin/ComparisonComponents/ComparisonRadarChartComponent.vue";
import { TypedVue } from "@/store/types";
import { Component, Prop, Ref } from "vue-property-decorator";
import { ProjectTheme, ProjectReportData, V2Project, SearchReportData } from "@/store/admin/adminTypes";
import { Trait, TRAITS, TRAIT_CLASS_RANGES } from "@/lib/consts";
import { SchemasCandidateAssessment } from "@/lib/serviceTypes";
import { ColorMap } from "@/lib/charts/HighChartThemes";
import MarkdownIt from "markdown-it";
import { Getter } from "vuex-class";
import { Getters } from "@/store/admin/adminGetters";

const md = new MarkdownIt({ html: true });
const namespace = "admin";

@Component({
  components: {
    ReportRenderer,
    ComparisonRadarChartComponent,
    CompetencyMatrix,
    CandidateSearchTable,
    CompetenciesListing,
    CompetenciesToggleSlideout,
  },
})
export default class TeamEffectivenessReport extends TypedVue {
  public showNames = true;

  public comparisonCandidates: ComparisonAssessmentItem[] = Vue.observable([]);
  public comparisonBenchmarks: ComparisonBenchmarkItem[] = Vue.observable([]);
  public reportData: SearchReportData | null = null;

  public candidatesView = "rankings";
  public candidatesRowsExpanded = false;
  public showCandidatesTableCompetencyBreakdown = false;

  public matrixView: MatrixView = "must-have";
  public allowMatrixReorder = false;
  public selectedPreset: ComparisonChartPreset | null = null;
  public allowedCompetencies: Trait[] = [...TRAITS];

  public matrixFilterTags: string[] = [];
  public matrixFilterLeadershipStyle: string[] = [];
  public showMatrixFilter = false;
  public matrixFilterText = "";

  @Prop({ required: true })
  readonly report!: ProjectReportData;

  @Prop({ required: true })
  readonly project!: V2Project;

  @Ref("candidate-table") candidateTable: CandidateSearchTable | undefined;

  @Getter(Getters.GET_CURRENT_PROJECT_ROOT_BENCHMARK_ID, { namespace })
  public rootBenchmarkId!: string;

  get matrixCacheKey() {
    return `matrix-${this.reportId}`;
  }

  get loading() {
    return this.reportData === null;
  }

  get projectTheme(): ProjectTheme {
    return (this.project?.theme as ProjectTheme) ?? ProjectTheme.DEFAULT_TA;
  }

  get reportId() {
    return this.$route.params.id;
  }

  get traitClassRanges() {
    return TRAIT_CLASS_RANGES;
  }

  get managerAssessment() {
    for (const assessment of this.reportData?.assessments || []) {
      if (this.reportData?.managers?.includes(assessment.identifier)) {
        return assessment;
      }
    }

    return null;
  }

  get candidateAssessments() {
    return this.reportData?.assessments.filter((a) => this.reportData?.candidates.includes(a.identifier)) || [];
  }

  get teamAssessments() {
    return this.reportData?.assessments.filter((a) => this.reportData?.team.includes(a.identifier)) || [];
  }

  get uniqueTags() {
    return this.reportData?.tags ? Array.from(new Set(Object.values(this.reportData.tags).flat())) : [];
  }

  get assessmentTags() {
    // This overwrites the reportData.tags field itself
    const baseTags = this.reportData?.tags || {};

    for (const assessment of this.reportData?.assessments || []) {
      if (this.reportData?.team.includes(assessment.identifier)) {
        baseTags[assessment.identifier] = [...(baseTags[assessment.identifier] || []), "team"];
      }
      if (this.reportData?.managers.includes(assessment.identifier)) {
        baseTags[assessment.identifier] = [...(baseTags[assessment.identifier] || []), "manager"];
      }
      if (this.reportData?.candidates.includes(assessment.identifier)) {
        baseTags[assessment.identifier] = [...(baseTags[assessment.identifier] || []), "candidate"];
      }
    }

    return baseTags;
  }

  get uniqueReadinessLevels() {
    return this.comparisonCandidates
      .map((c) => c.data.readinessScore)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((v) => ({ name: this.$t(`Report::Readiness::textShort::${v}`), item: v }));
  }

  get filteredAssessments() {
    return this.reportData?.assessments
      .filter((a) => this.matrixFilterText === "" || a.candidate.name.toLowerCase().includes(this.matrixFilterText.toLowerCase()))
      .filter(
        (x) =>
          (this.matrixFilterTags.length === 0 && this.matrixFilterLeadershipStyle.length === 0) ||
          this.matrixFilterTags.some((t) => this.reportData?.tags[x.identifier]?.includes(t)) ||
          this.matrixFilterLeadershipStyle.includes(x.scoring.readiness as string),
      );
  }

  get showCandidateTableRowToggle() {
    return Object.values(this.reportData?.customText.candidates || {}).some((c) => c.length > 140);
  }

  public closeMatrixFilter() {
    this.showMatrixFilter = false;
  }

  public clearAllMatrixFilters() {
    this.matrixFilterLeadershipStyle = [];
    this.matrixFilterTags = [];
  }

  public presetSelected(preset: ComparisonChartPreset) {
    this.selectedPreset = preset;
  }

  public presetDeSelected() {
    this.selectedPreset = null;
  }

  public toggleMatrixReorder() {
    this.allowMatrixReorder = !this.allowMatrixReorder;
  }

  public setMatrixView(value: MatrixView) {
    this.matrixView = value;
  }

  async mounted() {
    this.reportData = this.report.data as SearchReportData;
    this.setMatrixView(this.reportData.demoMode ? "top-priority" : "must-have");
    this.competencyListUpdated([...TRAITS]);

    this.comparisonBenchmarks = Vue.observable<ComparisonBenchmarkItem[]>(
      this.project.benchmarks?.map(
        (b) =>
          ({
            data: b as any,
            id: b.identifier,
            hidden: false,
            highlighted: false,
            locked: false,
            selected: this.rootBenchmarkId === b.identifier || false,
            color: localStorage.getItem(`report-chartcolor-${b.identifier}`) ?? (b.colour || ColorMap[this.projectTheme].default),
            initialColor: b.colour || ColorMap[this.projectTheme].default,
          } as ComparisonBenchmarkItem),
      ) ?? [],
    );
    this.comparisonCandidates = Vue.observable<ComparisonAssessmentItem[]>(
      this.reportData.assessments.map(
        (c) =>
          ({
            data: { ...(c as unknown as SchemasCandidateAssessment), assessmentId: c.identifier, traitScores: c.scoring.trait_scores, readinessScore: c.scoring.readiness, candidateName: c.candidate.name },
            id: c.identifier,
            hidden: false,
            highlighted: false,
            locked: false,
            selected: false,
            color: localStorage.getItem(`report-chartcolor-${c.identifier}`) ?? ColorMap[this.projectTheme][(c.scoring.readiness as string) ?? "default"],
            initialColor: ColorMap[this.projectTheme][(c.scoring.readiness as string) ?? "default"],
          } as ComparisonAssessmentItem),
      ),
    );
  }

  renderMarkdown(text: string) {
    return md.render(text || "");
  }

  getTraitPriorities(level: number) {
    return Object.keys(this.reportData?.traitPriorities || {}).filter((trait) => this.reportData?.traitPriorities[trait] === level);
  }

  viewGuide(): void {
    window.open("https://assets.system-3.com/web-assets/report/report-guide.pdf");
  }

  public competencyListUpdated(competencies: string[]) {
    this.allowedCompetencies = [...(competencies as Trait[])];
  }
}
