import { render, staticRenderFns } from "./TeamEffectivenessReport.vue?vue&type=template&id=632b999d&scoped=true"
import script from "./TeamEffectivenessReport.vue?vue&type=script&lang=ts"
export * from "./TeamEffectivenessReport.vue?vue&type=script&lang=ts"
import style0 from "./TeamEffectivenessReport.vue?vue&type=style&index=0&id=632b999d&prod&lang=scss&scoped=true"
import style1 from "./TeamEffectivenessReport.vue?vue&type=style&index=1&id=632b999d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "632b999d",
  null
  
)

export default component.exports